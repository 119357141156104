<template>
  <div id="app">
    <NoPageMsg msg="Good to see you again!"/>
  </div>
</template>

<script>
import NoPageMsg from './components/NoPageMsg.vue'

export default {
  name: 'App',
  components: {
    NoPageMsg
  }
}
</script>

<style>
html {
  width: 100%;
  height: 100%;
  background-color: #14366f;
}
body {
  background-image: url("@/assets/bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #503e2c;
  padding-top: 70px;
}
</style>
