<template>
  <div class="msg-wrapper">
    <img alt="Ann logo" class="logo" src="../assets/logo.svg" width=100>
    <h1>{{ msg }}</h1>
    <p>
      The access period for this demo has expired. <br/>
      If you'd like to renew your access or have any further questions, please don't hesitate to reach out to us at <a href="mailto:daniel@ann-education.com" target="_blank">daniel@ann-education.com</a>.
    </p>
    
    <footer>
      <strong>Daniel Hippertt, </strong><br/>
      Head of Global Operations
    </footer>
  </div>
</template>

<script>
export default {
  name: 'UrlList',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.logo {
  filter: invert(1);
  margin: 10px 0;
}
a {
  cursor: pointer !important;
  filter: invert(1);
}
.msg-wrapper {
    width: 450px;
    padding: 20px;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border-radius: 20px;
}
@media screen and (max-width: 450px) {
  .msg-wrapper {
      width: 86%;
      padding: 5%;
  }
  h1 {
    font-size: 28px;
  }
}

.button {
  display: inline-block;
  font-weight: 400;
  outline: none;
  margin: 15px 0 0;
  padding: 10px 20px;
  background-color: #ff8719;
  color: #fff;
  border: 1px solid #b97842;
  border-radius: 5px;
  font-size: 1.17em;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}
.button:hover {
  background-color: #c96d02;
}
footer {
  text-align: left;
  font-weight: 100;
  line-height: 1.5em;
  margin-top: 40px;

}
h1 {
  margin: 15px 0 0;
  font-weight: bold;
  letter-spacing: 0.05em
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
p {
  font-weight: 600;
  line-height: 1.5em;
}

</style>
